import { useMemo } from "react";

import DOMPurify from "dompurify";
import "./rafsurveycreator.scss";
import RichTextEditorField from "./RichTextEditorField";

const RAFContentEditor = ({ question, value, onChange }) => {
  const isReadOnly = question.readOnly;
  const displayMode = question.survey.mode === "display";
  const disabled = isReadOnly || displayMode;

  const sanitizedHtml = useMemo(() => DOMPurify.sanitize(value), [value]);

  const isDesignMode = question.survey.isDesignMode;

  return (
    <div>
      {isDesignMode ? (
        <>
          <RichTextEditorField
            value={value}
            onChange={(newContent) => onChange(newContent)}
            disabled={disabled}
          />
        </>
      ) : (
        <span
          dangerouslySetInnerHTML={{
            __html: sanitizedHtml,
          }}
        ></span>
      )}
    </div>
  );
};

export default RAFContentEditor;

import React from "react";

import { Question } from "survey-core";
import {
  IsNotNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  hexToRGBA,
  isNotNullAndUndefined,
} from "../../../../../RAFComponents/helpers/utils";
import { RAFStatusNameWithColor } from "../../../../../constants/Common/Constants";
import moment from "moment";
import { MessageComponent } from "@syncfusion/ej2-react-notifications";

interface RAFProcessStepRendererProps {
  question?: Question;
  stepusertype?: string;
  steptype?: string;
  assigneesettings?: any;
  assigneeteamsettings?: any;
  deadline?: number;
  calculationtype?: string;
}

const RAFProcessStepRenderer: React.FC<RAFProcessStepRendererProps> = ({
  question,
  stepusertype,
  steptype,
  assigneesettings,
  assigneeteamsettings,
  deadline,
  calculationtype,
}) => {
  let displayMode = false;
  if ((question.survey as any).mode === "display") {
    displayMode = true;
  }

  let isDesignMode = question.survey.isDesignMode;

  let disabled = false;
  if (displayMode === true) {
    disabled = true;
  }

  // Get the value of the question
  let questionValue = (question.survey as any).getValue(question.name);

  if (!question.defaultValue) {
    question.defaultValue = {};
  }

  let questiondefaultvalue = question.defaultValue;
  questiondefaultvalue.deadline = deadline;
  questiondefaultvalue.calculationtype = calculationtype;
  questiondefaultvalue.stepusertype = stepusertype;
  questiondefaultvalue.steptype = steptype;
  questiondefaultvalue.assignee = assigneesettings && assigneesettings["Value"];
  questiondefaultvalue.assigneeuid =
    assigneesettings && assigneesettings["UID"];
  questiondefaultvalue.assigneeteam =
    assigneeteamsettings && assigneeteamsettings["Value"];
  questiondefaultvalue.assigneeteamuid =
    assigneeteamsettings && assigneeteamsettings["UID"];
  question.defaultValue = questiondefaultvalue;
  questiondefaultvalue.status = "Not Started";

  let assignee = "";
  let assigneeTeam = "";
  if (stepusertype === "User") {
    if (isNotNullAndUndefined(assigneesettings)) {
      assignee = assigneesettings["Value"];
    }
  } else {
    if (isNotNullAndUndefined(assigneeteamsettings)) {
      assigneeTeam = assigneeteamsettings["Value"];
    }
  }

  let currentStatus = question && question.defaultValue["status"];
  currentStatus = questionValue && questionValue["status"];

  let dateValue = questionValue && questionValue["duedate"];
  let localDate = isNotNullAndUndefined(dateValue)
    ? convertUTCDateToLocalTimezone(dateValue)
    : null;
  let dueDateValue = isNotNullAndUndefined(localDate)
    ? moment(localDate).format("DD/MM/YYYY")
    : null;

  const statusNameWithColor =
    isNotNullAndUndefined(currentStatus) &&
    isNotNullAndUndefined(RAFStatusNameWithColor[currentStatus])
      ? RAFStatusNameWithColor[currentStatus]
      : {
          Value: null,
          DisplayName: null,
          Color: "#333",
          LightColor: "#f0f0f0",
        };

  const messageContentTemplate = () => {
    let message = "";
    if (steptype === "Checklist") {
      message = `This is ${steptype} step. You can switch to a Task or Approval`;
    } else if (steptype === "Task") {
      message = `This is a ${steptype} step. Assign a person and set a due date`;
    } else {
      message = `This is an ${steptype} step. You can change it to Task or Checklist if needed`;
    }
    return <div style={{ fontStyle: "oblique" }}>{message}</div>;
  };

  return (
    <div>
      {isDesignMode && isNotNullAndUndefined(steptype) && (
        <div className="cursor-pointer">
          <MessageComponent
            id="msg_info"
            content={messageContentTemplate.bind(this)}
          ></MessageComponent>
        </div>
      )}

      {(steptype === "Approval" || steptype === "Task") && (
        <>
          <div className={isDesignMode ? "w-100 ps-2 pt-2" : "w-100 ps-2"}>
            <div
              className="row bp-new-header g-2 g-md-3 flex-nowrap"
              style={{
                paddingBottom: isDesignMode === false && "10px",
                borderBottom: isDesignMode === false && "1px solid #e0e0e0",
              }}
            >
              <div
                className={
                  "col bp-new-header__title bp-new-header__titleHeight"
                }
              >
                <div className="w-100 overflowX-hidden">
                  <div className="row gx-2 gy-0">
                    <div className="col">
                      <div className="row gy-1 gx-0 align-items-center">
                        <div className="col-12">
                          <div className="body_3_light">
                            <span>Status:</span>
                          </div>
                        </div>
                        <div className="col-12 d-flex align-items-center">
                          <span
                            className="raf_badge raf_xsm ecllipseFirstline"
                            style={{
                              backgroundColor: isNotNullAndUndefined(
                                statusNameWithColor
                              )
                                ? hexToRGBA(statusNameWithColor.Color, 0.1)
                                : "#333",
                              color: statusNameWithColor.Color,
                              border: `1px solid ${hexToRGBA(
                                statusNameWithColor.Color,
                                0.5
                              )}`,
                            }}
                          >
                            {IsNotNullOrWhiteSpace(statusNameWithColor)
                              ? statusNameWithColor.DisplayName
                              : IsNotNullOrWhiteSpace(currentStatus)
                              ? currentStatus
                              : "Not set"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row gy-1 gx-0 align-items-center">
                        <div className="col-12">
                          <div className="body_3_light">
                            {/* <span className="fas fa-user pe-2"></span> */}
                            <span>Assignee:</span>
                          </div>
                        </div>
                        <div className="col-12">
                          <span className="ecllipseFirstLine body_2">
                            {stepusertype === "User" ? (
                              <>
                                {IsNotNullOrWhiteSpace(assignee)
                                  ? assignee
                                  : "Not set"}
                              </>
                            ) : (
                              <>
                                {IsNotNullOrWhiteSpace(assigneeTeam)
                                  ? assigneeTeam
                                  : "Not set"}
                              </>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="row gy-1 gx-0 align-items-center">
                        <div className="col-12">
                          <div className="body_3_light">
                            {/* <span className="fas fa-calendar-days pe-2 e-btn-icon"></span> */}
                            <span>Due on:</span>
                          </div>
                        </div>
                        <div className="col-12">
                          <span className="ecllipseFirstLine body_2">
                            {isNotNullAndUndefined(dueDateValue)
                              ? dueDateValue
                              : "Not set"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {steptype === "Checklist" && <></>}
    </div>
  );
};

export default RAFProcessStepRenderer;

import { useCallback, useMemo, useState } from "react";
import {
  RichTextEditorComponent,
  Inject,
  HtmlEditor,
  Toolbar,
  Image,
  Audio,
  Video,
  QuickToolbar,
  PasteCleanup,
  Link,
} from "@syncfusion/ej2-react-richtexteditor";
import { debounce } from "lodash";
import React from "react";

const RichTextEditorField = ({ value, onChange, disabled }) => {
  const [editorContent, setEditorContent] = useState(value);

  const handleContentChange = useCallback(
    debounce((event) => {
      if (event.value !== editorContent) {
        setEditorContent(event.value);
        if (onChange) onChange(event.value);
      }
    }, 500),
    [editorContent, onChange]
  );

  const toolbarSettings = useMemo(
    () => ({
      items: [
        "Bold",
        "Italic",
        "Underline",
        "StrikeThrough",
        "FontName",
        "FontSize",
        "FontColor",
        "BackgroundColor",
        "|",
        "Formats",
        "Alignments",
        "OrderedList",
        "UnorderedList",
        "Outdent",
        "Indent",
        "|",
        "CreateLink",
        "Image",
        "Audio",
        "Video",
        "|",
        "ClearFormat",
        "Print",
        "SourceCode",
        "|",
        "Undo",
        "Redo",
      ],
      enableFloating: true,
    }),
    []
  );

  //   function actionBegineHandler(args) {
  //     if (args.requestType === "EnterAction") {
  //       args.cancel = true;
  //     }
  //   }

  return (
    <RichTextEditorComponent
      value={editorContent}
      change={handleContentChange}
      disabled={disabled}
      toolbarSettings={toolbarSettings}
      pasteCleanupSettings={{
        prompt: true,
        plainText: false,
        keepFormat: false,
      }}
      inlineMode={{ enable: false }}
      insertImageSettings={{ saveFormat: "Base64" }}
      enterKey="BR"
      shiftEnterKey="BR"
      //actionBegin={actionBegineHandler.bind(this)}
      style={{ minHeight: "100px" }}
    >
      <Inject
        services={[
          HtmlEditor,
          Toolbar,
          Image,
          Link,
          QuickToolbar,
          Audio,
          Video,
          PasteCleanup,
        ]}
      />
    </RichTextEditorComponent>
  );
};

export default React.memo(RichTextEditorField);

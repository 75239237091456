import { ITheme, Question } from "survey-core";
import "survey-core/defaultV2.css";
import "survey-core/survey.i18n";
import "survey-creator-core/survey-creator-core.css";
import "survey-creator-core/survey-creator-core.i18n";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { customThemeProcess } from "./custom-theme-process";
import { SharpLightPanelless } from "survey-core/themes";
import { useEffect, useState } from "react";
import { isNotNullAndUndefined } from "../../../../../RAFComponents/helpers/utils";
import {
  complianceformquestion,
  setPageChangeEvent,
  setSurveyTheme,
  setTOCProgressTemplate,
  updateRAFSurveyLocaleBP,
} from "./Initializations";
//import * as Survey from "survey-core";
import { SvgRegistry } from "survey-core";
import { iconDescriptionShowSvg, iconDescriptionHideSvg } from "./icons";
import { ICreatorOptions } from "survey-creator-core";
import { IsAdminQueryString } from "../../../../../RAFComponents/helpers/AppHelper";
import { atom } from "recoil";

SvgRegistry.registerIconFromSvg("description-show", iconDescriptionShowSvg);
SvgRegistry.registerIconFromSvg("description-hide", iconDescriptionHideSvg);

export const processStepType = "processstep";

export const procesRecordUID = atom({
  key: "procesRecordUID", // unique ID for the atom
  default: null, // initial state (could be null, "Completed", etc.)
});

const getCreatorOptions = (
  isSuperAdmin: boolean,
  isESIOFormsUrl: boolean
): ICreatorOptions => {
  let questionTypes = [];
  // if (isSuperAdmin === false) {
  //   if (isESIOFormsUrl === true) {
  //     questionTypes = [
  //       "text",
  //       "checkbox",
  //       "customdropdownquestion",
  //       //"dropdown",
  //       "radiogroup",
  //       "tagbox",
  //       "boolean",
  //       "comment",
  //       "file",
  //       "image",
  //       "html",
  //       "panel",
  //       "signaturepad",
  //       "ratingquestion",
  //       "complaincequestion",
  //       "autocompletelookupquestion",
  //       "customrichtextquestion",
  //     ];
  //   } else {
  //     questionTypes = ["ratingquestion", "complaincequestion"];
  //   }
  // }
  // const allQuestionTypes = Survey.QuestionFactory.Instance.getAllTypes();

  // const excludedTypes = ["customactionquestion"]; // Specify the types you want to hide

  // // Filter the toolbox items to exclude specific types
  // const filteredToolboxItems = allQuestionTypes.filter(
  //   (item) => !excludedTypes.includes(item)
  // );

  questionTypes = [
    "text",
    "checkbox",
    "customdropdownquestion",
    //"dropdown",
    "radiogroup",
    "tagbox",
    "boolean",
    "comment",
    //"file",
    "image",
    //"html",
    //"panel",
    //"signaturepad",
    //"autocompletelookupquestion",
    //"customrichtextquestion",
    //"processstep",
    //"qrcodequestion",
    //"complianceformquestion",
    // "customcomplaincedocsquestion",
    //"employeedocumentquestion",
    //"clientdocumentquestion",
    //"tiptapeditorquestion",
    "contenteditorquestion",
  ];

  const creatorOptions: ICreatorOptions = {
    showLogicTab: isSuperAdmin || isESIOFormsUrl ? true : false,
    isAutoSave: true,
    //showSurveyTitle: false,
    showJSONEditorTab: isSuperAdmin ? true : false,
    showThemeTab: false,
    questionTypes: questionTypes,
    allowShowEmptyTitleInDesignMode: false,
  };

  return creatorOptions;
};

function SurveyCreatorRenderComponent() {
  let url = window.location.href; // Get the current URL
  const isSuperAdmin = IsAdminQueryString();
  let isESIOFormsUrl = url.includes("ProcessLibrary");

  const [fullPropertiesMode, setFullPropertiesMode] = useState(false);

  useEffect(() => {
    if (isSuperAdmin) {
      setFullPropertiesMode(true);
    }
    return () => {
      window.localStorage.removeItem("survey-json");
    };
  }, []);

  const creator = new SurveyCreator(
    getCreatorOptions(isSuperAdmin, isESIOFormsUrl)
  );
  creator.showToolbox = false;

  updateRAFSurveyLocaleBP();

  function isStepQuestion(obj) {
    return (
      !!obj &&
      (obj.name === "assignee" ||
        obj.name === "status" ||
        obj.name === "duedate" ||
        obj.name === "completiondate" ||
        obj.name === "deadline" ||
        obj.name === "steptype")
    );
  }

  const propertiesToShowInSurvey = ["title"];
  const propertiesToShowInQuestion = [
    "name",
    "title",
    "description",
    "placeholder",
    "isRequired",
    "visible",
    "choices",
    "displayStyle",
    //"rateType",
    //    "rateValues",
    "rateCount",
    // "showEvidence",
    // "showComment",
    // "showActions",
    // "showChoices",
    "contentMode",
    "imageLink",
    "imageFit",
    "imageHeight",
    "imageWidth",
    "altText",
    "width",
    "html",
    "labelTrue",
    "labelFalse",
    "swapOrder",
    "customChoices",
    "ratingCount",
    "referenceDescription",
    "customurl",
    "moduleName",
    "customFilter",
    "readOnly",
    "allowFiltering",
    "allowClear",
    "customchoices",
    "correctAnswer",
    //"allowStorage",
    "relatedmodule",
    "steptype",
    "stepusertype",
    "assigneesettings",
    "assigneeteamsettings",
    "deadline",
    "calculationtype",
    "htmlcontent",
  ];
  const propertiesToShowInPanel = ["name", "title", "state", "visible"];
  const propertiesToShowInPage = [
    "name",
    "title",
    "visibleIf",
    "enableIf",
    "requiredIf",
  ];

  creator.showToolbox = false;

  if (isSuperAdmin === false) {
    creator.showPropertyGrid = false;
  }

  creator.onElementAllowOperations.add((_, options) => {
    const obj = options.obj as Question;
    // Disallow restricted users to delete questions,
    if (obj.isQuestion) {
      if (isStepQuestion(obj)) {
        options.allowDelete = false;
        options.allowCopy = false;
        options.allowChangeType = false;
      }
    }

    if (obj.getType() === processStepType) {
      options.allowCopy = false;
      options.allowDelete = false;
      options.allowChangeType = false;
      options.allowChangeRequired = false;
    }

    if (obj.isPanel) {
      options.allowCopy = false;
      options.allowDelete = false;
      options.allowChangeType = false;
      options.allowChangeRequired = false;
    }
    const survey = _.survey;
    const isFirstPage = options.obj === survey.pages[0];

    if (options.obj.getType() === "page" && isFirstPage) {
      options.allowDelete = false;
    }
    if (options.obj.getType() === "page") {
      options.allowCopy = false;
    }
  });

  creator.onCollectionItemAllowOperations.add((_, options) => {
    // Disallow restricted users to delete columns via adorners on the design surface
    options.allowDelete = !isStepQuestion(options.item);
  });

  creator.onGetPropertyReadOnly.add((_, options) => {
    const obj = options.obj as Question;
    // Disallow restricted users to modify the `name` property for questions

    if (
      options.property.name === "name" ||
      options.property.name === "choices" ||
      options.property.name === "customurl"
    ) {
      options.readOnly = isStepQuestion(obj);
      if (obj.getType() === processStepType || obj.getType() === "page") {
        options.readOnly = true;
      }
      if (obj.name.includes("headerField")) {
        options.readOnly = true;
      }
    }
  });

  creator.onSurveyInstanceCreated.add((_, optionsCreator) => {
    optionsCreator.survey.applyTheme(customThemeProcess as ITheme);
    // optionsCreator.survey.applyTheme(customThemeProcess);
    optionsCreator.survey.applyTheme(SharpLightPanelless);

    if (
      optionsCreator.area === "designer-tab" ||
      optionsCreator.area === "preview-tab"
    ) {
      setPageChangeEvent(optionsCreator.survey, optionsCreator.area);
      setTOCProgressTemplate(optionsCreator.survey);
      setSurveyTheme(optionsCreator.survey);
    }
  });

  // Hide multiple properties
  const hidePropertyNames = ["maxTimeToFinish", "navigationTitle"];
  creator.onShowingProperty.add((_, options) => {
    if (fullPropertiesMode) {
      options.canShow = true;
      return;
    }

    if (options.obj.getType() === "page") {
      options.canShow = hidePropertyNames.indexOf(options.property.name) === -1;
    }

    if (options.obj.getType() === "survey") {
      options.canShow =
        propertiesToShowInSurvey.indexOf(options.property.name) > -1;
    } else if (options.obj.getType() === "panel") {
      options.canShow =
        propertiesToShowInPanel.indexOf(options.property.name) > -1;
    } else if (options.obj.getType() === "page") {
      options.canShow =
        propertiesToShowInPage.indexOf(options.property.name) > -1;
    } else {
      options.canShow =
        propertiesToShowInQuestion.indexOf(options.property.name) > -1;
    }
  });

  creator.saveSurveyFunc = (saveNo, callback) => {
    window.localStorage.setItem("survey-json", creator.text);
    //console.log({ creator });
    callback(saveNo, true);
    // saveSurveyJson(
    //     "https://your-web-service.com/",
    //     creator.JSON,
    //     saveNo,
    //     callback
    // );
  };

  creator.onModified.add(() => {
    window.localStorage.setItem("survey-json", creator.text);
  });

  creator.onQuestionAdded.add((_, options) => {
    //console.log("onQuestionAdded", options);

    let currentPage = options.page;
    let pageName = currentPage.name;
    let totalquestions = currentPage.questions.length;
    let newQuestion = options.question;

    // // Create a unique name for the new question to prevent duplicates
    // let uniqueIndex = totalquestions;

    // // Check if the question is a duplicate by comparing names
    // let existingNames = currentPage.questions.map((q) => q.name);

    // // Loop to find a unique name for the new question
    // while (existingNames.includes(`${pageName}_contentField_${uniqueIndex}`)) {
    //   uniqueIndex++; // Increment the index until we find a uniq
    // }
    if (newQuestion.getType() !== processStepType) {
      //newQuestion.name = `${pageName}_contentField_${uniqueIndex}`;
      if (newQuestion.getType() === complianceformquestion) {
        // Ensure newQuestion.defaultValue is defined
        if (!newQuestion.defaultValue) {
          newQuestion.defaultValue = {};
        }
        let questiondefaultvalue = newQuestion.defaultValue;
        questiondefaultvalue.relatedmodule = "Employee";
        newQuestion.defaultValue = questiondefaultvalue;
      }
    }
  });

  let creatorJson = window.localStorage.getItem("survey-json");

  if (isNotNullAndUndefined(creatorJson)) {
    creator.text = creatorJson;
  } else {
    creator.JSON = {
      title: "Process Title",
      logoPosition: "right",
      pages: [],
      showTitle: false,
      showCompletedPage: false,
      name: "Step Title",
      questionErrorLocation: "bottom",
      completeText: "Complete",
      clearInvisibleValues: "none",
      showTOC: true,
      allowCompleteSurveyAutomatic: false,
      //goNextPageAutomatic: true,
      //widthMode: "responsive",
    };
  }

  return (
    <div className="h-100 w-100">
      <SurveyCreatorComponent creator={creator} />
    </div>
  );
}

export default SurveyCreatorRenderComponent;
